/* You can add global styles to this file, and also import other style files */

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import 'ngx-toastr/toastr-bs4-alert';

.z-index-1{
  z-index: 1;
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

::-webkit-scrollbar-thumb {
  background-color: #363636;
}

.cursor-pointer{
  cursor: pointer;
}

.modal-backdrop{
  z-index: 1050!important;
}

// .modal-dialog{
//   position: absolute!important;
//   left: 50%;
//   top: 47%;
//   transform: translate(-50%, -50%)!important;
//   width: 500px!important;
// }

.modal-footer{
  justify-content: inherit!important;
}

.large-modal .modal-dialog{
  min-width: 80vw!important;
  max-width: 80vw!important;
}

.height-100 {
  height: 100vh;
  display: inline-table;
  width: 100%;
}

.cdk-overlay-container{
  z-index: 1051!important;
}

.disabled {
  cursor: not-allowed!important;
}

.tab-pane{
  min-height: 300px;
}

.card-border{
  border: #c6c6c7 1px solid;
  border-radius: 10px;
  margin: 0px;
  padding: 1rem;
}

.trash{
  position: absolute;
  right: 2px;
  border: 1px solid #c6c6c7;
  background: #ffffff;
  border-radius: 22px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  top: -3px;
  cursor: pointer;
}

.toggle-arrow{
  position: absolute;
  right: 39px;
  border: 1px solid #c6c6c7;
  background: #ffffff;
  border-radius: 22px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  top: -3px;
  cursor: pointer;
}

.fa-clone{
  position: absolute;
  right: 76px;
  border: 1px solid #c6c6c7;
  background: #ffffff;
  border-radius: 22px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  top: -3px;
  cursor: pointer;
}

.min-card-border{
  min-height: 268px;
}
